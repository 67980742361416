























import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: { PageTitleHeader }
})
export default class NotAuthorizedView extends Vue {}
